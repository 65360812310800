import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";

import Slider from "../Slider/SliderLogos";

const StyledSlider = styled.div`
  .slick-dots {
    ${tw`relative flex! justify-center space-x-2 mt-10 md:mt-12`}
    li {
      ${tw`h-0.5 w-5 bg-white bg-opacity-50 mr-0 transition-all duration-300 ease-linear`}
      button {
        ${tw`h-0.5 w-5`}
      }
      &.slick-active {
        ${tw`bg-opacity-100`}
      }
    }
  }
  /* .slick-slide {
    ${tw`mx-8`}
  } */
`;

const LogoCloud = ({ className }) => {
  const data = useStaticQuery(graphql`
    {
      logo1: file(relativePath: { eq: "about-us/clients/hyatt.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 200)
        }
      }
      logo2: file(relativePath: { eq: "about-us/clients/pendry.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 200)
        }
      }
      logo3: file(relativePath: { eq: "about-us/clients/Loews Hotels.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 200)
        }
      }
      logo4: file(relativePath: { eq: "about-us/clients/omnihotels.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 200)
        }
      }
      logo5: file(relativePath: { eq: "about-us/clients/Miraval.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 200)
        }
      }
    }
  `);

  const logos = [
    {
      logo: data.logo1.childImageSharp.gatsbyImageData,
    },
    {
      logo: data.logo2.childImageSharp.gatsbyImageData,
    },
    {
      logo: data.logo3.childImageSharp.gatsbyImageData,
    },
    {
      logo: data.logo4.childImageSharp.gatsbyImageData,
    },
    {
      logo: data.logo5.childImageSharp.gatsbyImageData,
    },
  ];

  return (
    <section className={`${className || "mb-20 md:mb-40"}`}>
      <div className="container px-0 md:px-4 mx-auto">
        <StyledSlider className="relative mx-auto">
          <Slider>
            {logos.map((logo, i) => {
              return (
                <div key={i} className="h-full text-center">

                    <GatsbyImage image={logo.logo} />

                </div>
              );
            })}
          </Slider>
        </StyledSlider>
      </div>
    </section>
  );
};

export default LogoCloud;
